$base-color: #e00034;

$base-color-2022: rgb(15,35,140);

$light-blue: rgb(5,200,240);

$sub-color: #000;
//$accent-color: #13a3d7;
//$accent-color: rgb(255,0,188);
$accent-color: rgb(250,4,186);

$font-color: $base-color-2022;
$font-color-light: #7c7c7b;

$link-color: $font-color;
//$link-color-dimmed: #c7c2ba;
$link-color-dimmed: rgb(206,209,211);
$link-color-hover: $accent-color;
$breadcrumb-color: rgb(150,155,160);

$bg-color: #575756;
$bg-color-dimmed: #c7c2ba;

$base-font: "museo-sans", "Arial", sans-serif;
$base-font-medium: "museo-sans", "Arial", sans-serif;
$base-font-cond-bold: "museo-sans", "Arial", sans-serif;
$sub-font: "museo-sans", "Arial", sans-serif;

$container-width: 1200px;
$gutter-width: 15px;
$large-desktop-breakpoint: 1400px;
$tablet-breakpoint: 1024px;
$mobile-breakpoint: 750px;
$float-breakpoint: 650px;
