body {
  font-family: $base-font;
  color: $base-color-2022;
}

h1 {
  font-family: $base-font-cond-bold;
  font-weight: 500;
  font-size: 30px;
  color: $font-color;
  margin-top: 10px;
  text-transform: uppercase;
}

h1.white {
  color: #fff;
  font-weight: normal;
}

h1.highlight {
	color: $accent-color;
	//background-color: #12a2d7;

  display: inline;
  line-height: 1;
  padding: 0px 0px;
  margin-bottom: 20px;
  text-transform: uppercase;
  -webkit-box-decoration-break: clone;
  -ms-box-decoration-break: clone;
  -o-box-decoration-break: clone;
  box-decoration-break: clone;

  & + * {
    margin-top: 20px;
  }
}

h3 {
  font-family: $base-font;
  font-weight: 500;
  font-size: 36px;
  color: $light-blue;
  margin: 24px 0;
}

.plain {
	font-family: "museo-sans", "Arial", sans-serif;
  font-size: 24px;
  font-weight: 500;
  color: $base-color-2022;
  margin-top: 10px;
	text-transform: uppercase;

  @include mobile {
    font-size: 26px;
  }
}

h5 {
  font-family: $base-font-medium;
  font-size: 18px;
  line-height: 26px;
  color: $base-color-2022;
  font-weight: 700;
}
h5.table-title {
  color: $light-blue;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}
h5.img-title {
  font-family: $sub-font;
  color: $light-blue;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  //margin-top: -15px;
  margin-bottom: 0px;
}
p.caption {
  font-family: $sub-font;
  color: $light-blue;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  margin-top: -15px;
  margin-bottom: 10px;
}
h6 {
  margin: 15px 0 0;
  font-family: $base-font-medium;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: $base-color-2022;
}

h1 + h5 {
  margin-top:10px;
  margin-bottom: 10px;
}

.content-container {

  .close-web {
    position: absolute;
    right: 15px;
  }

  ul {
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
  }


  .footnotes {
    padding-left: 15px;
    li {
      color: #666;
      font-size: 12px;
      line-height: 18px;
      font-weight: 300;
    }
  }

  ol:not(.footnotes) {
    // counter-reset: olcounter;
    list-style: none;

    li {
      font-size: 16px;
      line-height: 24px;
      font-weight: 300;
      margin-bottom: 10px;

      // &:before {
      // 	content: counter(olcounter);
      // 	counter-increment: olcounter;
      //
      //   position: absolute;
      //   margin-left: -20px;
      //   font-size: 20px;
      //   font-weight: 100;
      //   color: red;
      // }
    }
  }
}
.quote-block {
  margin: 20px 0;
  padding: 10px 0;
  border-top: 1px solid $light-blue;
  border-bottom: 1px solid $light-blue;

  &.widthColumn-1 {
    width: 50%;
  }
}

.highlight-block {
  background-color: $base-color-2022;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;

  h1 {
    //background-color: #fff;
    //color: $link-color-dimmed;
   // background-color: #000;
    color: #fff;
    padding: 0;
  }

  img {
    //max-width: 40%;
    position: relative;
    margin: 20px auto;
  }

  p {
    font-family: $sub-font;
    color: #fff;
    &.caption {
      color: $light-blue;
    }
  }
  
}

p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;

  a {
    font-weight: 700;
    color: $light-blue;
    @include transition(0.3s color);

    &:hover {
      color: $link-color-hover;
    }
  }

  &.quote {
    color: $light-blue;
    font-family: $sub-font;
    font-size: 24px;
    line-height: 36px;
    font-weight: 300;
    margin-bottom: 5px;
  }

  &.quote-author {
    margin-top:18px;
    margin-bottom:0;
    font-size: 16px;
  }


}

li a {
  color: $link-color;
  @include transition(0.3s color);

  &:hover {
    color: $link-color-hover;
  }
}

.call-to-action {
  position: relative;
  display: block;
  margin-bottom: 20px;
  height: 300px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  .overlay {
    position: absolute;
    bottom: 20px;
    left: 0px;
    line-height: 12px;
    //max-width: calc(100% - 20px);
    width: 100%;
    background-color: $base-color-2022;
    padding: 11px;
  }

  h3 {
    //display: inline;
    font-family: $base-font-cond-bold;
    font-weight: 500;
    color: $accent-color;
    font-size: 16px;
    line-height: 19px;
    //background-color: $accent-color;
    padding: 2px 4px;
    text-transform: uppercase;
    margin: 0 0 1px 0;
    text-decoration: none;
    -webkit-box-decoration-break: clone;
    -ms-box-decoration-break: clone;
    -o-box-decoration-break: clone;
    box-decoration-break: clone;
  }

  p {
    font-family: $sub-font;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: #fff;
    //font-style: italic;
    //background-color: $sub-color;
    margin: 0;
    text-decoration: none;

    display: inline;
    padding: 6px 6px 3px;
    -webkit-box-decoration-break: clone;
    -ms-box-decoration-break: clone;
    -o-box-decoration-break: clone;
    box-decoration-break: clone;
  }
}
