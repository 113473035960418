.slideInUp {
  animation: slideInUp 1s;
  animation-fill-mode:forwards;
}

@media screen and (max-width: 1024px) {
  .slideInUp {
    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
  }
}

@keyframes slideInUp {
  from {
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }
}

.homepage-blocks {
  margin-left: 0;
  margin-right: 0;

  .yt-embed {
    max-width: 100%;
    max-height: 100%;
  }

  .yt-overlay {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
  }

  > div {
    position: relative;
    height: 400px;

    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;

    &:hover {
      .overlay {
        // @extend .slideInUp;
      }
    }

    a {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .overlay {
    visibility: hidden;
    position: absolute;
    bottom: 27px;
    left: 0px;
    width: 100%;
    background-color: $base-color-2022;
    //padding: 14px;
    padding: 9px 14px 0px 14px;

    @include transition(0.3s opacity);

    @include tablet {
      visibility: visible !important;
    }

    //div:first-of-type {
    div {
      margin-bottom: 10px;
      padding-left: 4px;
    }

    h3 {
      //display: inline;
      font-family: $base-font-cond-bold;
      font-weight: 500;
      font-size: 24px;
      line-height: 30px;
      color: $accent-color;
      //background-color: $accent-color;
      padding: 0px;
      margin: 0;
      text-transform: uppercase;
      -webkit-box-decoration-break: clone;
      -ms-box-decoration-break: clone;
      -o-box-decoration-break: clone;
      box-decoration-break: clone;
    }

    p {
      //display: inline;
      font-family: $sub-font;
      font-size: 15px;
      line-height: 18px;
      //font-style: italic;
      font-weight: 700;
      color: #fff;
      //background-color: $sub-color;
      //padding: 5px 6px 2px;
      margin: 0;
      -webkit-box-decoration-break: clone;
      -ms-box-decoration-break: clone;
      -o-box-decoration-break: clone;
      box-decoration-break: clone;
    }
  }
}
