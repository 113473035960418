header {
  height: 80px;
  transition: 0.5s height;

  &.minimized {
    @media screen and (min-width: 1025px) {
      height: 10px;
      overflow: hidden;
    }
  }

  @include tablet {

    .row {

      > div:first-of-type {
        min-height: 80px;
      }
    }
  }
}
.mobile-pdfoptions {
  display: none;
  position: absolute;
  right: 61px;
  top: 23px;
  font-family: "museo-sans", "Arial", sans-serif;
  font-size: 30px;
  font-weight: normal;
  text-decoration: none;
  text-transform: uppercase;
  color: #c7c2ba;
  -webkit-transition: 0.3s color;
  -moz-transition: 0.3s color;
  -ms-transition: 0.3s color;
  -o-transition: 0.3s color;
  transition: 0.3s color;
  &.active {
    color:black;
  }
  @include tablet {
    display: block;

  }
}
.mobile-searchbutton {
  display: none;
  position: absolute;
  right: 103px;
  top: 14px;

  @include tablet {
    display: block;
    margin-right: 24px;
  }
}

.main-nav-trigger {
  display: none;
  position: absolute;
  right: 25px;
  top: 25px;
  font-family: $base-font-cond-bold;
  font-weight: normal;
  font-size: 30px;
  font-weight: 700;
  color: $link-color;
  text-decoration: none;

  @include tablet {
    display: block;
  }
}

nav {

  &.main-nav {
    margin-top: 13px;

    @include tablet {
      display: none;
      position: absolute;
      padding-top: 65px;
      margin-top: 0;
      left: 0;
      top: 80px;
      background-color: $base-color-2022;
      width: 100%;
      height: calc(100vh - 80px);
      overflow-y: auto;
    }

    /* Main nav (level 1) */
    ul {
      list-style: none;
      padding: 0;

      .parent {
        position: relative;
        display: inline-block;

        @include tablet {
          display: block;
          background-color: $base-color-2022;
        }

        &:hover {

          > a {
            color: $base-color-2022;
          }
        }

        > a {
          display: block;
          font-family: $base-font-cond-bold;
          font-size: 30px;
          font-weight: 500;
          padding: 10px 25px 25px;
          text-decoration: none;
          text-transform: uppercase;
          color: $link-color-dimmed;

          @include tablet {
            font-size: 20px;
            padding: 5px 25px;
          }

          @include transition(0.3s color);

          &.active {
            color: $base-color-2022;
          }



          &#pdfoptions {
            padding-left: 5px;
          }
        }

        &:hover {

          ul {
            display: block;
          }
        }
      }

      /* Main nav (level 2) */
      ul {
        display: none;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 0;
        margin-top: 70px;
        padding-top: 10px;
        padding-bottom: 10px;
        width: 250px;
        background-color: $base-color-2022;
        z-index: 999;

        @include tablet {
          display: block;
          position: relative;
          left: 0;
          transform: none;
          margin-top: 0;
          width: 100%;
        }

        @media screen and (min-width: 1025px) {
          &:before {
            position: absolute;
            content: " ";
            display: block;
            top: -20px;
            left: 50%;
            margin-left: -20px;
            width: 0;
            height: 0;
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
            border-bottom: 20px solid $base-color-2022;
          }
        }

        li {

          &:hover {

            a {
              background-color: $accent-color;
              color:white;
            }
          }

          a {
            display: block;
            font-size: 14px;
            font-weight: 700;
            line-height: 22px;
            color: #fff;
            padding: 5px;
            text-decoration: none;
            padding: 5px 25px;

            // @include tablet {
            //   font-size: 14px;
            //   line-height: 18px;
            // }
            &.active {
              background-color: $accent-color;
              color:white;
            }

            @include transition(0.3s background-color);
          }
        }
      }
    }
  }
}

.breadcrumbs {
  list-style: none;
  padding: 0;
  margin: 0;

  @include tablet {
    padding-right: 25px;
  }

  li {
    display: inline-block;
    font-family: $base-font-cond-bold;
    font-weight: 500;
    font-size: 14px;
    color: $breadcrumb-color;
    text-transform: uppercase;
  }

  a {
    font-family: $base-font-cond-bold;
    font-weight: 500;
    font-size: 14px;
    color: $breadcrumb-color;
    text-decoration: none;
    @include transition(0.3s color);

    &:hover {
      color: $base-color-2022;
    }
  }

  .divider {
    margin: 0 5px;
  }
}

.accordion:last-of-type {
  margin-bottom: 40px;
}

.accordion .content {
  display: none;
}

.accordion h2 {
  cursor: pointer;
  border-top: 1px solid $light-blue;
  padding-top: 10px;
  margin-bottom: 10px;

  &.plain {
    position: relative;
    padding-right: 40px;

    &:after {
      display: block;
      position: absolute;
      content: "";
      height: 20px;
      width: 20px;
      right: 0;
      top: 14px;
      background: url('../img/open-close-btn.png');
      background-position: 0 0;
      background-size: 20px;
    }

    &.open {
      &:after {
        background-position: 0 -20px;
      }
    }

    &.subparagraph {
      border: 0;
      cursor: auto;

      &:after {
        display: none !important;
      }
    }
  }
}
.main-nav-trigger img {
  display:none;
}
.main-nav-trigger {
  background-image: url('../img/mobile-menu-icon.png');
  height: 22px;
  width: 22px;
  top:30px;
}
.main-nav-trigger.active {
  background-image: url('../img/close-btn.png');
}
@media screen and (max-width: 1024px) {
  nav.main-nav ul .parent > a {
      font-size: 24px;
      padding: 5px 25px;
  }
  nav.main-nav > ul > li.parent > a,
  nav.main-nav > ul > li.parent > a.active {
    color: $accent-color;
  }
  nav.main-nav ul ul {
    background-color: $base-color-2022;
  }
  nav.main-nav ul ul li:hover{
    background-color: $base-color-2022;
  }
  nav.main-nav ul ul li a {
    color:white;
    font-size: 16px;
  }
}
main .content-container .container table ul:not(.breadcrumbs) {
  margin-top:0;
}
// background: url('../img/open-close-btn.png');
// background-position: 0 0;
// }
//
// &.open {
// &:after {
//   background-position: 0 -28px;
// }
// }
footer .col-6 {
  float: right;
  width: auto;
  &.text-right {
    float:right;
  }
  &.text-left {
    float:left;
  }
}
