@charset "utf-8";

* {
  box-sizing: border-box;
}

body {
  background-color: $base-color-2022;
  padding: 80px 0 0 0;
  margin: 0;

  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

ul,
ol {
  margin: 0;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  padding: 0px 25px;
  background-color: #fff;
  z-index: 9998;

  .logo {
    display: inline-block;
    margin-top: 22px;
    width: 157px;
    
    @include mobile {
      max-width: 120px;
      margin-top: 29px;
    }
  }

  .logo-title {
    display: none;
    position: absolute;
    left: 205px;
    top: 23px;
    font-size: 30px;
    font-family: $base-font-cond-bold;
    font-weight: normal;
    text-transform: uppercase;
    color: $link-color-dimmed;

    @include tablet {
      display: block;

    }

    @include mobile {
      left: 155px;
      //top: 30px;
      //font-size: 14px;
    }

    @media screen and (max-width: 600px) {
      display: none;
    }
  }
}

main {
  //margin-top: 80px;

  > .wrapper {
    min-height: calc(100vh - 172px);
  }

  .content-container {

    .container {

      ul:not(.breadcrumbs):not(.pdf) {
        //margin-top: -10px;
        margin-bottom: 20px;
        padding-left: 20px;

        ul {
          margin-top: 0;
        }

        list-style: none;

        li::before {content: "\2022"; color: $base-color-2022;
          display: inline-block; width: 20px;
            margin-left: -20px;
        }
      }

      ol:not(.footnotes) {
        padding-left: 25px;
        list-style: none;
        &.start {
          counter-reset: olcount;
        }
        li {
          position: relative;
        }

        li:before {
          position: absolute;
          left: -25px;
          top: 0;
          color: $base-color-2022;
          content: counter(olcount) ".";
          counter-increment: olcount;
        }
      }

      .highlight-block {
        ol,ul {
          /*padding-left:0;*/
        }
      }
    }
  }
}

footer {
  background-color: $base-color-2022;
  padding: 20px 25px;

  .row {
    position: relative;
  }

  .moveTop {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
  }

  ul {
    margin: 0;
    padding: 15px 0 16px 0;
    list-style: none;

    li {
      display: inline-block;
      margin-right: 10px;

      a {
        color: #fff;
        text-decoration: none;
        font-weight: 500;
        font-size: 15px;
        //font-style: italic;
      }
    }
  }

  .logo {
    width: 157px;
    margin-top: 8px;

    @include mobile {
      max-width: 120px;
      margin-top: 25px;
    }
  }
}

.table-overlay,
.table-zoom  {
  display: none;
}

.searchdiv.content-container .container,
.fs.content-container .container {
    background-color: rgb(244, 245, 255);
}

.content-container {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;

  .container {
    background-color: rgba(255,255,255);
  }

  .border-right {
    border-right: 4px solid #fff;
    padding-right: 25px;

    @include tablet {
      border-right: 0px;
      padding-right: 0;
    }
  }
}

.no-padding {
  padding: 0;
}

.text-right {
  text-align: right;
}

p {
  margin-top: 0;
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.introduction p {
  font-family: "museo-sans", "Arial", sans-serif;
  font-size: 24px;
  line-height: 36px;
  //font-style: italic;
  font-weight: 300;
  color: $base-color-2022;

  @include mobile {
    font-size: 20px;
  }
}

@include tablet {
  .table-wrapper {
    overflow-x: auto;
  }
}

.mobile-searchbutton,
#searchbutton {
    background:url(../img/search.svg) 0 no-repeat;
    &.active {
      background:url(../img/search-selected.svg) 0 no-repeat;
    }
    &:hover {
      background:url(../img/search-selected.svg) 0 no-repeat;
    }
    width: 19px;
    height: 24px;
    display: inline-block;
    padding: 27px 15px;
}

#search {
  position: relative;
  margin-top: -10px;

  .textinput {
    width: 100%;
    border: 0 none;
    font-size: 15px;
    padding: 8px 10px;
  }

  .searchbutton {
    background:url(../img/search-black.png) 0 no-repeat;
    position: absolute;
    margin-left: -25px;
    width: 13px;
    height: 17px;
    margin-top: 8px;
    background-size: 13px 17px;
    border: 0;
  }
}

.media-page {
  float: none;
  position: absolute;
  top: 100px;
  right: 47px;
  padding-right: 0;
}

.searchresults {
  width: 100%;
  padding-top: 15px;

  p {
    margin-top: 15px;
    color:#7c7c7b;
  }

  p.breadcrumb {
    margin-top: -10px;
    color: #000;
  }

  .black {
    color: #000;
  }

  ol {
    list-style: none;
    padding-left: 0;

    li{
      border-top: 1px solid #000;
    }
    li:before {
      top: 15px!important;
    }
    li:last-child {
      border-bottom: 1px solid #000;
    }
  }

  a {
    text-decoration: none;
    }
}

.embed-container {
  height: 500px;
  margin-bottom: 25px;
  iframe {
    height: 500px;
  }

  @include mobile {
    height: 200px;

    iframe {
      height: 200px;
    }
  }
}
.float {

  @media screen and (min-width: ($float-breakpoint + 1)) {
    float:left;
    margin-right: 13px;
    width:calc(50% - 13px);
  }
}
.float-end {
  @media screen and (min-width: ($float-breakpoint + 1)) {
    float:left;
    width:48%;
  }
}

h2.plain.subparagraph {
  font-family: $base-font-cond-bold;
  font-weight: 500;
  font-size: 24px;
  line-height: 26px;
  color: $accent-color;
  text-transform: uppercase;
}
h5,
h2.plain.subparagraph {
  margin-bottom: 10px;
  margin-top:10px;
}
h5 {
  margin-top: 22px;
}
main .content-container .container .sitemap {
  ul > li {
    margin: 0 0 25px 0px;
    & > a {
      color:white;
      font-size:20px;
    }
  }
  ul > li > ul > li {
    margin: 5px 0px;
    & > a {
      color: black;
      font-size: 16px;
      line-height: 20px;
      padding: 5px 0px;
    }
  }
  ul, li {
    list-style: none;
    margin:0;
    padding:0;

    a:hover, a:visited, a:link, a:active
    {

      text-decoration: none;
    }
  }

}
