
.no-minimized {
  height: 80px !important;
}

#pdfdiv {
  position: fixed;
  top:80px;
  left:0px;
  height:calc(100vh - 170px);
  /*height:calc(100vh - 80px);*/
  width:100%;
  //background-color:#c7c2ba;
  background-color: rgb(241,240,245);
  display:none;
  padding-top:30px;
  h1 {
    //color:#fff;
    margin-bottom: 0px;
  }
  h2 {
    font-family: $base-font;
    font-size: 18px;
    color: $font-color-light;
    margin-top: 10px;
    margin-bottom: 0px;
  }

  a {
    color:#000;
    text-decoration: none;
  }
  .content-container {
    padding-top:0;
    .container {
      background-color: rgb(241, 240, 245);
    }
  }
  .breadcrumbs {
    margin-bottom:10px;
    a {
      //color:#fff;
    }
  }

  a.remove {
    background:url(../img/close-btn-grey.png) 0 no-repeat;
    width: 22px;
    height: 22px;
    display: inline-block;
  }

  .box {
    padding-left:15px;

    a {
      color: $font-color-light;
    }
  }
}
#close-search.close-button {
  top:110px;
}
.close-button {
  float:right;
  font-family: $base-font-cond-bold;
  font-weight: normal;
  font-size:30px;
  padding-right:25px;
  color:#e00034;
  // position: absolute;
  // right: 25px;
  // top:30px;
}

ul.pdf {
  padding:10px 0;
  list-style: none;
  font-size: 18px;
  line-height: 30px;
}
