.table-container {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid $sub-color;
  @include tablet {
    overflow-x: auto;
  }
}

table {

  &.standard {

    p {
      margin: 0;
      padding: 0 4px;
      font-size: 16px;
      line-height: 24px;

      @include mobile {
        font-size: 13px;
      }
    }

    .header1 {
      p {
        font-family: $base-font-cond-bold;
        font-weight: normal;
        font-size: 26px;
        color: $font-color-light;
        text-transform: uppercase;
        margin-bottom: 25px;
        margin-top: 13px;
      }
    }

    .header2 {
      p {
        font-family: $base-font-medium;
        font-size: 18px;
        color: $sub-color;
        font-weight: 500;
        margin-bottom: 25px;
      }
    }

    tr {

      &.margin-large {

        th, td {
          padding-bottom: 20px
        }
      }
    }

    th {

      p {
        font-family: $sub-font;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        //font-style: italic;
      }
    }

    thead {

      tr:first-of-type {
        th {
          border-top: 1px solid $sub-color;
          padding: 6px 0 2px;
        }
      }

      tr:last-of-type {
        th {
          &.highlight {
            //border-bottom: 4px solid $base-color;
          }
        }
      }
    }

    td {
      vertical-align: top;
      &.highlight {
        background-color: rgb(255,255,255);
      }
      &.highlight-alt {
        background-color: rgb(244,245,255);
      }
      &.first-highlight {
        border-top: 4px solid $accent-color;
      }
      
    }

    &.non-financial {
      td {
        &.highlight {
          background-color: rgb(244,245,255);
        }
      }
    }

    tr {

      td:first-of-type,
      th:first-of-type {
        padding-left: 0;
      }
    }
    tr.border-row {
      td.border-thin,
      td.border-thick {
        border-bottom: 1px solid $sub-color;
        p {
          border-bottom:none;
        }
      }
    }
    td.border-thin,
    td.border-thick {
      p {
        display: inline-block;
        border-bottom: 1px solid $sub-color;
      }
    }

    td.border-thick {

      p {
        position: relative;

        &:after {
          position: absolute;
          bottom: -5px;
          left: 0;
          content: "";
          display: inline-block;
          width: 100%;
          border-bottom: 1px solid $sub-color;
        }
      }
    }
  }
}
