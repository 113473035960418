@import 'variables';

.container {
  width: 100%;
  max-width: $container-width;
  margin: 0 auto;
  padding: 0 15px;
}

.content-container {
  padding: 30px;
  @include mobile {
    padding: 30px 0;
  }
  .container {
    padding: 20px 25px;
  }
}

.sidebar {
  margin-top: 30px;
  @include largeDesktop {
    padding:0 15px 0 10px !important;
  }
}

[class*="col-"] {
    float: left;
    padding: 0 $gutter-width;
}

.row {
  margin-left: -$gutter-width;
  margin-right: -$gutter-width;
}

.row::after {
    content: "";
    clear: both;
    display: block;
}

@for $i from 1 through 12 {
  .col-#{$i} { @include grid(#{$i}); }
}

@for $i from 0 through 12 {
  .col-#{$i}-offset { @include grid-offset(#{$i}); }
}

.hide-desktop {
  @media screen and (min-width: ($tablet-breakpoint + 1)) {
    display: none !important;
  }
}

@include largeDesktop {
  @for $i from 1 through 12 {
    .col-lg-#{$i} { @include grid(#{$i}); }
  }

  @for $i from 0 through 12 {
    .col-lg-#{$i}-offset { @include grid-offset(#{$i}); }
  }
}

@include tablet {
  [class*="col-"] {
    @include grid(12);
    margin-left: 0;
  }

  @for $i from 1 through 12 {
    .col-md-#{$i} { @include grid(#{$i}); }
  }

  @for $i from 0 through 12 {
    .col-md-#{$i}-offset { @include grid-offset(#{$i}); }
  }

  .hide-tablet {
    display: none !important;
  }
}

@include mobile {

  @for $i from 1 through 12 {
    .col-sm-#{$i} { @include grid(#{$i}); }
  }

  @for $i from 0 through 12 {
    .col-sm-#{$i}-offset { @include grid-offset(#{$i}); }
  }

  .hide-mobile {
    display: none !important;
  }
}
